import "../styles/App.scss";
import Benefits from "./Benefits/Benefits";
import Faq from "./FAQ/Faq";
import Footer from "./Footer/Footer";
import Header from "./Header/Header";
import Location from "./Location/Location";
import Welcome from "./Welcome/Welcome";
import AlertForm from "./AlertForm/AlertForm";
import Work from "./Work/Work";
import Catalog from "./Catalog/Catalog";
import WhatDo from "./WhatWeDo/WhatDo";
import Services from "./Services/Services";
import ContactForm from "./ContactForm/Form";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <Toaster />
      <div>
        <Header />
        <Welcome />
        <WhatDo />
        <Catalog />
        <Benefits />
        <Services />
        <Work />
        <Faq />
        <Location />
        <ContactForm />
        <Footer />
      </div>
      <AlertForm />
    </div>
  );
}

export default App;
