export const nav_links = [
  {
    label: "Про нас",
    href: "#about",
  },
  {
    label: "Послуги",
    href: "#services",
  },
  {
    label: "Каталог",
    href: "#catalog",
  },
  {
    label: "Портфоліо",
    href: "#work",
  },
  {
    label: "FAQ",
    href: "#faq",
  },

  {
    label: "Контакти",
    href: "#contact",
  },
];
