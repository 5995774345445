import { Circle } from "lucide-react";
import styles from "./Services.module.scss";
import { services_labels } from "../../static_store/services";

const Services = () => {
  return (
    <section className={styles.root} id="services">
      <article className={styles.wrapper}>
        <div className={styles.header}>
          <h3>Наші послуги</h3>
          <p>
            Індивідуальний підхід до кожного клієнта, незалежно від обсягу
            проекту
          </p>
        </div>
        <div className={styles.services}>
          {services_labels.map((item) => {
            return (
              <div key={item} className={styles.serv_block}>
                <Circle fill="#0064c2" color="#0064c2" />
                <p>{item}</p>
              </div>
            );
          })}
        </div>
      </article>
    </section>
  );
};

export default Services;
