import Solar5 from "../assets/products/elitna_5kw-550x550.png";
import Solar6 from "../assets/products/elitna_5kw-550x5506kvt-550x550.png";
import Solar8 from "../assets/products/deye_8_premium-550x550.png";
import Solar10 from "../assets/products/10premiym-550x550.png";
import Solar12 from "../assets/products/deye_avatar_12kw-550x550.png";
import Solar30 from "../assets/products/300-1-550x550.png";
import Merezha30 from "../assets/products/econom-30-550x550.png";
import Merezha100 from "../assets/products/solar_station_100kw-550x550.png";
import Samsung from "../assets/products/5400369229_w640_h640_5400369229.jpg";
import Panasonic from "../assets/products/panasonic-aquarea-600x600.jpg";
import Cond from "../assets/products/cond.jpg";

export const products = [
  {
    image: Solar5,
    label:
      "Сонячна станція для власного споживання з акумуляторами під ключ на 5кВт на обладнанні Deye",
    price: "5 300",
  },
  {
    image: Solar6,
    label:
      "Сонячна станція для власного споживання з акумуляторами під ключ на 6кВт на обладнанні Deye",
    price: "5 700",
  },
  {
    image: Solar8,
    label:
      "Сонячна станція для власного споживання з акумуляторами під ключ на 8кВт на обладнанні Deye",
    price: "7 500",
  },
  {
    image: Solar10,
    label:
      "Сонячна станція для власного споживання з акумуляторами під ключ на 10кВт на обладнанні Deye",
    price: "9 900",
  },
  {
    image: Solar12,
    label:
      "Сонячна станція для власного споживання з акумуляторами під ключ на 12кВт на обладнанні Deye",
    price: "11 500",
  },
  {
    image: Solar30,
    label:
      "Сонячна станція для власного споживання з акумуляторами під ключ на 30кВт на обладнанні Deye",
    price: "26 000",
  },
  {
    image: Merezha30,
    label: "Мережева станція на 30кВт",
    price: "13 500",
  },
  {
    image: Merezha100,
    label: "Мережева станція на 100кВт",
    price: "42 000",
  },
  {
    image: Samsung,
    label: "Тепловий насос повітря-вода Samsung 16кВт до 250 м²",
    price: "5 500",
  },
  {
    image: Panasonic,
    label: "Тепловий насос повітря-вода Panasonic 16кВт до 250 м²",
    price: "6 200",
  },
  {
    image: Cond,
    label: "Тепловий насос повітря-вода Mycond 10кВт до 150 м²",
    price: "3 400",
    currency: "eur",
  },
  {
    image: Cond,
    label: "Тепловий насос повітря-вода Mycond 18кВт до 250 м²",
    price: "4 500",
    currency: "eur",
  },
];
