import AnchorLink from "react-anchor-link-smooth-scroll";
import styles from "./Header.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { setBurgerClicked } from "../../redux/slices/formSlice";
import { nav_links } from "../../static_store/navbar_links";

const Burger = () => {
  const dispatch = useDispatch();
  const formSlice = useSelector((state) => state.form);
  return (
    <section
      className={`${styles.burger_root} ${
        formSlice.isBurgerClicked ? styles.burger_show : ""
      }`}
    >
      <article className={styles.burger_wrapper}>
        <nav>
          {nav_links.map((link) => {
            return (
              <AnchorLink
                key={link.href}
                href={link.href}
                onClick={() => {
                  dispatch(setBurgerClicked(false));
                  document.body.style.overflowY = "scroll";
                }}
              >
                {link.label}
              </AnchorLink>
            );
          })}
        </nav>
        <AnchorLink
          className={styles.button}
          href="#form"
          onClick={() => {
            dispatch(setBurgerClicked(false));
            document.body.style.overflowY = "scroll";
          }}
        >
          Замовити консультацію
        </AnchorLink>
      </article>
    </section>
  );
};

export default Burger;
