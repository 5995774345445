import { useDispatch } from "react-redux";
import { products } from "../../static_store/products";
import styles from "./Catalog.module.scss";
import { setAlertContent, setClickedAlert } from "../../redux/slices/formSlice";

export default function Catalog() {
  const dispatch = useDispatch();

  const handleButtonClick = (value) => {
    dispatch(setAlertContent(value));
    dispatch(setClickedAlert(true));
  };

  return (
    <section className={styles.root} id="catalog">
      <article className={styles.wrapper}>
        <h3>Каталог</h3>
        <div className={styles.products}>
          {products.map((product) => {
            return (
              <div className={styles.product} key={product.label}>
                <div
                  className={styles.image}
                  style={{ backgroundImage: `url(${product.image})` }}
                />
                <p>{product.label}</p>
                <div>
                  <span>
                    {product.price} {product?.currency === "eur" ? "€" : "$"}
                  </span>
                  <button
                    onClick={() => handleButtonClick(product.label)}
                    id="buy-btn"
                  >
                    Замовити
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </article>
    </section>
  );
}
