import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isClickedAlert: false,
  isBurgerClicked: false,
  alertContent: "",
};

export const formSlice = createSlice({
  name: "form",
  initialState,
  reducers: {
    setClickedAlert(state, action) {
      state.isClickedAlert = action.payload;
    },
    setBurgerClicked(state, action) {
      state.isBurgerClicked = action.payload;
    },
    setAlertContent(state, action) {
      state.alertContent = action.payload;
    },
  },
});

export const { setClickedAlert, setBurgerClicked, setAlertContent } =
  formSlice.actions;
export default formSlice.reducer;
