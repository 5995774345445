import styles from "./Benefits.module.scss";

const circles = [
  {
    label: "Консультація",
    text: "На початковому етапі ми проводимо консультацію, спрямовану на виявлення потреб клієнта та надання найкращих рішень.",
    align: "left",
  },
  {
    label: "Асортимент",
    text: "Широкий вибір кондиціонерів різних моделей, потужностей та дизайнів для вибору найбільш підходящого варіанту.",
    align: "right",
  },
  {
    label: "Сервіс",
    text: "Наша компанія надає офіційну гарантію від виробника, а також гарантує якість виконаних робіт. Ми також забезпечуємо обслуговування в майбутньому, гарантуючи безперебійну роботу вашої системи.",
    align: "left",
  },
  {
    label: "Монтаж",
    text: "Ми здійснюємо безкоштовний виїзд на об'єкт для огляду та оцінки вартості монтажних робіт, щоб забезпечити вам максимальний комфорт і ефективність системи.",
    align: "right",
  },
  {
    label: "Енергоефективність",
    text: "Ми використовуємо продукцію, яка ефективно використовує енергію, зменшуючи витрати на оплату комунальних послуг.",
    align: "left",
  },
  {
    label: "Підтримка 24/7",
    text: "Наша команда завжди на зв'язку, готова відповісти на всі ваші запитання та надати необхідну допомогу щодо встановлення та експлуатації кондиціонеру.",
    align: "right",
  },
];

const Benefits = () => {
  return (
    <section className={styles.root} id="about">
      <article className={styles.wrapper}>
        <h3>Досвідчені працівники з індивідуальним підходом</h3>
        <p>
          Наша компанія пропонує спектр продуктів та послуг, які гарантують
          найвищу якість і задоволення потреб клієнтів.
        </p>
        <div className={styles.circles}>
          {circles.map((item) => {
            return (
              <div
                key={item.label}
                className={`${styles.item} ${
                  item.align === "left" ? styles.left : styles.right
                }`}
              >
                <span>{item.label}</span>
                <p>{item.text}</p>
              </div>
            );
          })}
        </div>
      </article>
    </section>
  );
};

export default Benefits;
