import styles from "./Footer.module.scss";

import Logo from "../../assets/logo-footer.png";
import Teleg from "../../assets/telegram-icon.png";
import Viber from "../../assets/viber-icon.png";
import { MapPin, PhoneIcon } from "lucide-react";

const Footer = () => {
  return (
    <section className={styles.root} id="contact">
      <article className={styles.wrapper}>
        <div className={styles.contact}>
          <div className={styles.logo_block}>
            <img alt="Logo" src={Logo} />
            <p>Створіть ідеальний клімат у вашому житті.</p>
          </div>
          <div className={styles.links}>
            {/* <div className={styles.links_block}>
              <a
                href="https://t.me/+380635196998"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Social" src={Teleg} />
              </a>
              <a
                href="viber://contact?number=+380635196998"
                target="_blank"
                rel="noreferrer"
              >
                <img alt="Social" src={Viber} />
              </a>
            </div> */}

            <div className={styles.text_block}>
              {/* <a href="tel:+380635196998">
                <PhoneIcon /> <p>+380(63) 519-69-98</p>
              </a> */}
              <a
                href="https://maps.app.goo.gl/4WJ67xRU8LaJF49q9"
                target="_blank"
                rel="noreferrer"
              >
                <MapPin /> <p>м.Ковель вулиця Соборна 1а</p>
              </a>
            </div>
          </div>
        </div>
      </article>
      <article className={styles.copy}>
        <p>Company © 2024 All Rights Reserved</p>
      </article>
    </section>
  );
};

export default Footer;
