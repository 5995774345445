import styles from "./Location.module.scss";

import Image from "../../assets/IMG_5113.webp";
import { MapPin } from "lucide-react";

const Location = () => {
  return (
    <section className={styles.root}>
      <article className={styles.wrapper}>
        <div className={styles.text_block}>
          <h4>Де нас знайти</h4>
          <p>
            Завітайте до нас за адресою що розташована в самому центрі Ковеля.
            Зручне розташування поруч з дорогою забезпечить легкий доступ для
            наших клієнтів. Очікуємо вашого візиту!
          </p>
          <div className={styles.location}>
            <MapPin />
            <p>м.Ковель вулиця Соборна 1а</p>
          </div>
          <a
            href="https://maps.app.goo.gl/4WJ67xRU8LaJF49q9"
            target="_blank"
            rel="noreferrer"
          >
            Знайти нас на карті
          </a>
        </div>
        <img alt="Location" src={Image} />
      </article>
    </section>
  );
};

export default Location;
