import { services } from "../../static_store/services";
import styles from "./WhatDo.module.scss";

export default function WhatDo() {
  return (
    <section className={styles.root} id="about">
      <article className={styles.wrapper}>
        <h3>Чим ми займаємось:</h3>
        <div className={styles.row_block}>
          {services.map((service) => {
            return (
              <div key={service.label}>
                <img alt={service.label} src={service.image} />
                <p>{service.label}</p>
              </div>
            );
          })}
        </div>
      </article>
    </section>
  );
}
