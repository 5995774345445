import Image1 from "../assets/solar-panels.png";
import Image2 from "../assets/heat-pump.png";
import Image4 from "../assets/conditioner.png";
import Image5 from "../assets/recuperation.png";
import Image6 from "../assets/fridge.png";

export const services = [
  {
    image: Image1,
    label: "Сонячні панелі під ключ",
  },
  {
    image: Image2,
    label: "Теплові насоси",
  },
  {
    image: Image6,
    label: "Промислове холодильне обладнання",
  },
  {
    image: Image4,
    label: "Кондиціонери",
  },
  {
    image: Image5,
    label: "Вентиляція",
  },
];

export const services_labels = [
  "Монтаж сонячних станцій під ключ",
  "Монтаж теплових насосів під ключ",
  "Консультації з проектування сонячних станцій",
  "Установка сонячних панелей",
  "Монтаж кондиціонерів тепло-холод",
  "Монтаж промислового холодильного обладнання",
  "Чистка та сервіс кондиціонерів",
  "Обслуговування кліматичного обладнання",
  "Проектування та монтаж рекуператорних систем",
  "Монтаж та обслуговування вентиляційних систем",
  "Монтаж системи опалення",
  "Теплові розрахунки та аудит енергії",
  "Установка системи охолодження",
  "Монтаж системи автоматизації кліматичного контролю",
];
