import styles from "./AlertForm.module.scss";

import { useDispatch, useSelector } from "react-redux";
import { setClickedAlert } from "../../redux/slices/formSlice";
import { useForm } from "react-hook-form";
import { sendFormToTelegram } from "../../utils/sendTelegram";

import { X } from "lucide-react";
import { useRef } from "react";
import toast from "react-hot-toast";

const AlertForm = () => {
  const formSlice = useSelector((state) => state.form);
  const dispatch = useDispatch();

  const alertRef = useRef(null);

  const handleClose = (value) => {
    dispatch(setClickedAlert(value));
  };

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    if (data.name.trim() === "" || data.phone.trim() === "") {
      toast.error("Заповніть, будь-ласка, необхідні поля!");
      return;
    }
    let string = `Проект: Climate Cold & Hot \nІм'я: ${data.name} \nНомер телефону: ${data.phone} \nПовідомлення: ${formSlice.alertContent} \n`;

    sendFormToTelegram(string);
    toast.success("Повідомлення успішно надіслано!");
    reset();
    dispatch(setClickedAlert(false));
  };

  return (
    <>
      <section
        ref={alertRef}
        className={`${styles.root} ${
          formSlice.isClickedAlert ? styles.show_form : ""
        }`}
      >
        <article className={styles.wrapper}>
          <X onClick={() => handleClose(false)} />
          <div className={styles.form_block}>
            <h5>Форма для зв'язку</h5>
            <form onSubmit={handleSubmit(onSubmit)}>
              <input type="text" placeholder="Ім'я" {...register("name")} />
              <input
                type="text"
                placeholder="Номер телефону"
                {...register("phone")}
              />
              <textarea
                placeholder="Повідомлення"
                value={formSlice.alertContent}
              />
              <button type="submit">Відправити</button>
            </form>
          </div>
        </article>
      </section>
      <div
        className={`${styles.overflow} ${
          formSlice.isClickedAlert ? styles.show : ""
        }`}
      />
    </>
  );
};

export default AlertForm;
