import styles from "./Work.module.scss";
import { lazy, Suspense } from "react";

import Image1 from "../../assets/work/photo_2024-03-30_13-47-08.jpg";
import Image2 from "../../assets/work/photo_2024-03-30_13-47-10.jpg";

import ImageNew1 from "../../assets/work/IMG_5497-min.JPG";
import ImageNew2 from "../../assets/work/IMG_5803-min.JPG";
import ImageNew3 from "../../assets/work/IMG_5884-min.JPG";
import ImageNew4 from "../../assets/work/IMG_5886-min.JPG";
import ImageNew5 from "../../assets/work/IMG_5887-min.JPG";
import ImageNew6 from "../../assets/work/IMG_6098-min.JPG";
import ImageNew7 from "../../assets/work/IMG_6099-min.JPG";
import ImageNew8 from "../../assets/work/IMG_6259-min.JPG";
import ImageNew9 from "../../assets/work/IMG_6431-min.JPG";
import ImageNew10 from "../../assets/work/IMG_6436-min.JPG";
import ImageNew11 from "../../assets/work/IMG_6257.JPG";
import ImageNew12 from "../../assets/work/IMG_6092-min.JPG";
import ImageNew13 from "../../assets/work/IMG_6256-min.JPG";
import ImageNew14 from "../../assets/work/IMG_6258-min.JPG";
import ImageNew15 from "../../assets/work/IMG_5518.jpg";
import ImageNew16 from "../../assets/work/IMG_5520.jpg";
import ImageNew17 from "../../assets/work/IMG_5806.jpg";
import ImageNew18 from "../../assets/work/IMG_5885.jpg";
import ImageNew19 from "../../assets/work/IMG_6139.jpg";
import ImageNew20 from "../../assets/work/IMG_6390.jpg";

import ImageNew21 from "../../assets/work/11zon_IMG_5646.webp";
import ImageNew22 from "../../assets/work/11zon_IMG_6348.webp";
import ImageNew23 from "../../assets/work/11zon_IMG_6472.webp";
import ImageNew24 from "../../assets/work/11zon_IMG_6528.webp";
import ImageNew25 from "../../assets/work/11zon_IMG_6597.webp";
import ImageNew26 from "../../assets/work/11zon_IMG_6689.webp";
import ImageNew27 from "../../assets/work/11zon_IMG_6694.webp";
import ImageNew28 from "../../assets/work/IMG_6471.jpg";
import ImageNew29 from "../../assets/work/IMG_6529.webp";
import ImageNew30 from "../../assets/work/IMG_6218.webp";
import ImageNew31 from "../../assets/work/IMG_5625.webp";
import ImageNew32 from "../../assets/work/IMG_5575.webp";

import Slim1 from "../../assets/work/photo_2024-03-30_14-28-45.jpg";
import Slim2 from "../../assets/work/photo_2024-03-30_14-28-48.jpg";
import Slim3 from "../../assets/work/photo_2024-03-30_14-28-51.jpg";

import Slim1_1 from "../../assets/work/photo_2024-03-30_14-32-04.jpg";
import Slim2_1 from "../../assets/work//photo_2024-03-30_14-32-15.jpg";
import Slim3_1 from "../../assets/work/photo_2024-03-30_14-32-17.jpg";

import Image6 from "../../assets/work/photo_2024-03-30_14-35-45.jpg";
import Image7 from "../../assets/work/photo_2024-03-30_14-35-55.jpg";
import Image8 from "../../assets/work/photo_2024-03-30_14-35-57.jpg";

import { Loader } from "lucide-react";

const WorkSlider = lazy(() => import("./WorkSlider"));
const SlimSlider = lazy(() => import("./SlimSlider"));

const images = [
  ImageNew1,
  ImageNew2,
  ImageNew3,
  ImageNew4,
  ImageNew5,
  ImageNew31,
  ImageNew32,
  Image1,
  Image2,
];
const images2 = [
  ImageNew6,
  ImageNew7,
  ImageNew8,
  ImageNew9,
  ImageNew10,
  ImageNew30,
  Image6,
  Image7,
  Image8,
];

const slim_1 = [
  ImageNew11,
  ImageNew12,
  ImageNew13,
  ImageNew28,
  ImageNew29,
  Slim1,
  Slim2,
  Slim3,
];
const slim_2 = [ImageNew14, ImageNew15, ImageNew16, ImageNew26, ImageNew27];

const slim_1_1 = [
  ImageNew17,
  ImageNew18,
  ImageNew24,
  ImageNew25,
  Slim1_1,
  Slim2_1,
  Slim3_1,
];
const slim_2_1 = [ImageNew19, ImageNew20, ImageNew21, ImageNew22, ImageNew23];

const Work = () => {
  return (
    <section className={styles.root} id="work">
      <article className={styles.wrapper}>
        <h4>Результат роботи/портфоліо</h4>
        <Suspense
          fallback={
            <div className={styles.loading}>
              <Loader />
            </div>
          }
        >
          <div className={styles.slider_row}>
            <WorkSlider images={images} />
            <div className={styles.slim}>
              <SlimSlider images={slim_1} />
              <SlimSlider images={slim_2} />
            </div>
          </div>
          <div className={styles.slider_row_2}>
            <div className={styles.slim}>
              <SlimSlider images={slim_1_1} />
              <SlimSlider images={slim_2_1} />
            </div>
            <WorkSlider images={images2} />
          </div>
          <div className={styles.mobile_block}>
            <WorkSlider images={images} />
            <WorkSlider images={slim_1} />
            <WorkSlider images={slim_2} />
            <WorkSlider images={slim_1_1} />
            <WorkSlider images={slim_2_1} />
            <WorkSlider images={images2} />
          </div>
        </Suspense>
      </article>
    </section>
  );
};

export default Work;
