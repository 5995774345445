import { useForm } from "react-hook-form";
import styles from "./Form.module.scss";
import { sendFormToTelegram } from "../../utils/sendTelegram";
import toast from "react-hot-toast";

const ContactForm = () => {
  const { register, handleSubmit, reset } = useForm();

  const onSubmit = (data) => {
    if (data.name.trim() === "" || data.phone.trim() === "") {
      toast.error("Заповніть, будь-ласка, необхідні поля!");
      return;
    }
    let string = `Проект: Climate Cold & Hot \nІм'я: ${data.name} \nНомер телефону: ${data.phone} \nПовідомлення: ${data.message} \n`;

    sendFormToTelegram(string);
    toast.success("Повідомлення успішно надіслано!");
    reset();
  };

  return (
    <section className={styles.root} id="form">
      <article className={styles.wrapper}>
        <div className={styles.form_block}>
          <h5>Форма для зв'язку</h5>
          <p>
            Цікавить наша пропозиція? Заповніть нашу зручну форму зв'язку, і ми
            зв'яжемося з вами найближчим часом.
          </p>
          <form onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Ім'я" {...register("name")} />
            <input
              type="text"
              placeholder="Номер телефону"
              {...register("phone")}
            />
            <textarea placeholder="Повідомлення" {...register("message")} />
            <button type="submit">Відправити</button>
          </form>
        </div>
        <div className={styles.etaps_block}>
          <div className={styles.etap}>
            <div>
              <h5>Відповідь на форму</h5>
              <p>Приблизний час: 1 год</p>
            </div>
          </div>
          <div className={styles.etap}>
            <div>
              <h5>Персоналізована консультація</h5>
              <p>Приблизний час: 15 хв</p>
            </div>
          </div>
          <div className={styles.etap}>
            <div>
              <h5>Професійне виконання робіт</h5>
              <p>Приблизний час: 2 год</p>
            </div>
          </div>
          <div className={styles.etap}>
            <h5>Експлуатація</h5>
          </div>
        </div>
      </article>
    </section>
  );
};

export default ContactForm;
