import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "react-headless-accordion";
import styles from "./Faq.module.scss";

import { Minus, Plus } from "lucide-react";
import { faq_info } from "../../static_store/faq_info";

const Faq = () => {
  return (
    <section className={styles.root} id="faq">
      <article className={styles.wrapper}>
        <h4>Часті запитання</h4>
        <Accordion className={styles.accordion}>
          {faq_info.map((info) => {
            return (
              <AccordionItem key={info.title}>
                {({ open }) => (
                  <>
                    <AccordionHeader>
                      <h4
                        className={`accordion-title ${open ? styles.open : ""}`}
                      >
                        {faq_info.indexOf(info) + 1}. {info.title}
                      </h4>
                      {open ? (
                        <Minus
                          strokeWidth={1}
                          className={`${open ? styles.open : ""}`}
                        />
                      ) : (
                        <Plus strokeWidth={1} />
                      )}
                    </AccordionHeader>
                    <AccordionBody>
                      <div className="accordion-body">{info.text}</div>
                    </AccordionBody>
                  </>
                )}
              </AccordionItem>
            );
          })}
        </Accordion>
      </article>
    </section>
  );
};

export default Faq;
