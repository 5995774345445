export const faq_info = [
  {
    title: "Які переваги має встановлення кондиціонера?",
    text: "Кондиціонери забезпечують комфортну температуру та вологість повітря в приміщенні, підвищуючи загальний рівень затишку. Вони також допомагають покращити якість повітря, фільтруючи пил та алергени. Сучасні моделі є енергоефективними, що дозволяє заощаджувати на рахунках за електроенергію. Крім того, кондиціонери можуть працювати в режимі обігріву, забезпечуючи комфорт цілий рік.",
  },
  {
    title: "Який обігрівач краще вибрати для мого приміщення?",
    text: "Вибір обігрівача залежить від багатьох факторів, включаючи розмір приміщення, наявність ізоляції, тип будівлі та ваші особисті потреби. Для невеликих кімнат підійдуть компактні електричні обігрівачі. Для більших приміщень ефективними можуть бути інфрачервоні обігрівачі або тепловентилятори. Якщо ви шукаєте економічне рішення для постійного обігріву, варто розглянути встановлення теплового насоса або системи центрального опалення. Наші спеціалісти з радістю проконсультують вас та допоможуть обрати найкращий варіант, враховуючи всі особливості вашого приміщення та побажання.",
  },
  {
    title: "Які гарантійні умови на ваші продукти?",
    text: "Ми надаємо гарантію на всі наші продукти та послуги. Терміни гарантії можуть варіюватися залежно від типу товару або послуги. Стандартна гарантія на кондиціонери та теплові насоси становить 2-5 років, на обігрівачі - 1-3 роки. На монтажні роботи ми надаємо гарантію 1 рік. Додатково ми пропонуємо розширені гарантійні пакети, які можуть збільшити термін гарантії до 7 років. У разі виникнення несправностей протягом гарантійного терміну, ми забезпечуємо безкоштовний ремонт або заміну обладнання. Детальні умови гарантії надаються при покупці кожного конкретного продукту.",
  },
  {
    title: "Як можу замовити консультацію або отримати додаткову інформацію?",
    text: "Ви можете зв'язатися з нами кількома способами. Найпростіший - заповнити форму зворотного зв'язку на нашому сайті, вказавши ваше питання та контактні дані. Також ви можете зателефонувати нам за номером +380(63) 519-69-98 в робочі години (пн-пт з 9:00 до 18:00). Ми також активні в соціальних мережах - ви можете написати нам у Facebook або Instagram. Наші спеціалісти завжди раді допомогти вам з усіма питаннями щодо вибору, встановлення та обслуговування кліматичного обладнання.",
  },
  {
    title: "Які переваги використання сонячних панелей?",
    text: "Сонячні панелі мають багато переваг. По-перше, вони дозволяють значно економити на рахунках за електроенергію, особливо в довгостроковій перспективі. По-друге, вони є екологічно чистим джерелом енергії, що допомагає зменшити ваш вуглецевий слід та сприяє захисту навколишнього середовища. Сонячні панелі також підвищують енергонезалежність вашого будинку, захищаючи вас від перебоїв у електромережі та зростання цін на електроенергію. Крім того, встановлення сонячних панелей може підвищити вартість вашої нерухомості. В Україні діє 'зелений' тариф, що дозволяє продавати надлишок виробленої електроенергії в мережу за вигідною ціною.",
  },
  {
    title: "Як часто потрібно проводити технічне обслуговування кондиціонера?",
    text: "Рекомендується проводити профілактичне обслуговування кондиціонера принаймні раз на рік, бажано перед початком сезону активного використання. Для систем, що працюють цілий рік або в забруднених умовах, може бути необхідне частіше обслуговування - раз на 6 місяців. Регулярне ТО включає очищення фільтрів, перевірку рівня холодоагенту, очищення теплообмінників та загальну діагностику системи. Це допомагає підтримувати ефективність роботи кондиціонера, подовжує термін його служби та забезпечує чистоту повітря у вашому приміщенні.",
  },
  {
    title:
      "Чи можна встановити систему 'розумний дім' для управління кліматом?",
    text: "Так, ми пропонуємо рішення для інтеграції кліматичного обладнання в систему 'розумний дім'. Це дозволяє централізовано керувати температурою, вологістю та якістю повітря у вашому домі через смартфон або планшет. Ви можете встановлювати розклади роботи обладнання, отримувати повідомлення про необхідність заміни фільтрів чи проведення ТО, а також оптимізувати енергоспоживання. Наші спеціалісти допоможуть вам вибрати сумісне обладнання та налаштувати його для максимального комфорту та енергоефективності.",
  },
  {
    title:
      "Які є варіанти фінансування для встановлення кліматичного обладнання?",
    text: "Ми розуміємо, що встановлення якісного кліматичного обладнання може бути значною інвестицією, тому пропонуємо різні варіанти фінансування. У нас є програми розстрочки платежу без переплат на термін до 12 місяців. Також ми співпрацюємо з кількома банками, які надають кредити на придбання та встановлення обладнання на вигідних умовах. Для проектів з енергозбереження, таких як встановлення теплових насосів або сонячних панелей, доступні державні програми компенсації частини вартості. Наші менеджери детально розкажуть про всі доступні опції та допоможуть вибрати найбільш підходящий для вас варіант фінансування.",
  },
];
