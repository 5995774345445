import { useState } from "react";
import styles from "./Welcome.module.scss";
import { sendFormToTelegram } from "../../utils/sendTelegram";
import toast from "react-hot-toast";

const Welcome = () => {
  const [phone, setPhone] = useState("");

  const onSubmit = () => {
    if (phone.trim() === "") {
      toast.error("Заповніть, будь-ласка, необхідні поля!");
      return;
    }
    let string = `Проект: Climate Cold & Hot \nНомер телефону: ${phone} \n`;

    sendFormToTelegram(string);
    toast.success("Повідомлення успішно надіслано!");
    setPhone("");
  };
  return (
    <section className={styles.root}>
      <article className={styles.wrapper}>
        <div className={styles.text_block}>
          <h1>
            Введіть свій номер телефону й ми зв'яжемося з вами найближчим часом.
          </h1>
          <div className={styles.form}>
            <div>
              <p>Номер телефону:</p>
              <input
                type="text"
                placeholder="+38 (__) ___ - __ - __"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <button onClick={onSubmit}>Замовити консультацію</button>
          </div>
        </div>
      </article>
    </section>
  );
};

export default Welcome;
